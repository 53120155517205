<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Reset Password v1 -->
      <b-card class="mb-0">

        <!-- logo -->
        <b-link class="brand-logo">
          <logo />

          <h2 class="brand-text text-primary ml-1">
            PRS - Axum
          </h2>
        </b-link>

        <b-card-title class="mb-1">
          Reset Password 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          To activate your account, please reset your password.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form method="POST" class="auth-reset-password-form mt-2" @submit.prevent="resetPassword">

            <!-- password -->
            <b-form-group label="New Password" label-for="reset-password-new">
              <validation-provider #default="{ errors }" name="Password" vid="Password" rules="required|password">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="reset-password-new" v-model="password" :type="password1FieldType"
                    :state="errors.length > 0 ? false : null" class="form-control-merge" name="reset-password-new"
                    placeholder="············" />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- confirm password -->
            <b-form-group label-for="reset-password-confirm" label="Confirm Password">
              <validation-provider #default="{ errors }" name="Confirm Password" rules="required|confirmed:Password">
                <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid' : null">
                  <b-form-input id="reset-password-confirm" v-model="cPassword" :type="password2FieldType"
                    class="form-control-merge" :state="errors.length > 0 ? false : null" name="reset-password-confirm"
                    placeholder="············" />
                  <b-input-group-append is-text>
                    <feather-icon class="cursor-pointer" :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-button block type="submit" variant="primary">
              Set New Password
            </b-button>
          </b-form>
        </validation-observer>

        <p class="text-center mt-2">
          <b-link @click="logout">
            <feather-icon icon="ChevronLeftIcon" /> Back to login
          </b-link>
        </p>

      </b-card>
      <!-- /Reset Password v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BCard, BCardTitle, BCardText, BForm, BFormGroup, BInputGroup, BInputGroupAppend, BLink, BFormInput, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    Logo,
    BCard,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: '',
      cPassword: '',
      password: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    resetPassword() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {

          // Make the API call to reset the password
          this.$http.put('/auth/me/resetPassword', { password: this.password })
            .then(response => {
              // Toast success message
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Password Changed Successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })

              // Log out the user
              this.logout()
            }).catch(err => {
              const errorMessage = (err.response && err.response.data && err.response.data.error) || 'Failed to reset password.'
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  text: errorMessage,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
